// THREE.JS FlyControls飞行器控件
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

// renderer渲染器
// useFlyControls(scene, renderer, camera, {}); //飞行器控件
export default (scene: any, filePath: string, coll: {
  scale:any,
  position:any
}) => {
  // 添加
  const loader = new GLTFLoader();
  loader.load(
    `${filePath}`,
    function (gltf: any) {
      // console.log(gltf);
      gltf.scene.scale.set(coll.scale[0], coll.scale[1], coll.scale[2]); // scale here
      gltf.scene.position.x = coll.position.x;
      gltf.scene.position.y = coll.position.y;
      gltf.scene.position.z = coll.position.z;
      scene.add(gltf.scene);
    },
    function (xhr) {
      // console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
    },
    function (error) {
      console.error(error);
    }
  );
};
