import scene from './scene';

//创建物体组件
import createplane from './mesh/plane';
import textGeometry from './mesh/textGeometry';
import loadImage from './mesh/loadImage';
import sky from './mesh/sky';

// import spriteMaterial from './mesh/spriteMaterial';
// 点精灵-黑点 spriteMaterial( { x: Math.random()*100, y: Math.random()*100, z: -10 })

// HOOKS方法-加载器
import useGLTFLoader from '@/hooks/useThree/model/useGLTFLoader';

export default function createMesh() {
  // 天空
  sky()
  // 背后的盒子
  // createplane();
  // 文字
  textGeometry();
  // 加载图片
  loadImage(require('@/assets/images/banner/a5.jpg'), { x: 0, y: 10, z: -5 });
  // loadImage(require('@/assets/images/banner/a3.jpg'), { x: 0, y: 34, z: -5 });

  // 绿色草地
  // 模型
  useGLTFLoader(scene, 'gltf/greenGrass.gltf', { scale: [9999, 1, 9999], position: { x: 1, y: -23, z: 1 } },);
  // useGLTFLoader(scene, 'gltf/greenGrass.gltf', { scale: [1, 1, 1],position:{ x: 12, y: -23, z:  12} },);
  for (let i = 0; i < 1000; i++) {
    let myRandom = Math.floor(Math.random() * (500 - -500 + 1)) + -500
    let myRandomZ = Math.floor(Math.random() * (500 - -500 + 1)) + -500
    console.log(myRandom);
    useGLTFLoader(scene, 'gltf/graphorigin_hua.gltf', { scale: [10, 5, 10], position: { x: myRandom, y: -20, z: myRandomZ } },);
    // useGLTFLoader(scene, 'gltf/graphorigin_hua.gltf', { scale: [10, 5, 10], position: { x: 5, y: -20, z: 5 } },);
  }

}
