import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import * as THREE from "three";
import { onMounted, ref } from 'vue'; //导入场景

import scene from "./three/scene"; //导入相机

import camera from "./three/camera"; // 导入GUI

import gui from "./three/gui"; //导入渲染器

import renderer from "./three/renderer"; //导入每一帧执行函数

import animate from "./three/animate"; //导入坐标轴

import axesHelper from "./three/axesHelper"; //导入初始化调整屏幕

import "./three/init"; //导入控制器

import controls from "./three/controls"; //导入物体

import createMesh from "./three/createMesh"; // import { cube, line, } from "./three/geometry.js";
//场景元素div

export default {
  setup: function setup(__props) {
    //场景组件
    var sceneDiv = ref(null); //添加相机

    scene.add(camera); //添加辅助坐标轴

    scene.add(axesHelper); //添加物体

    createMesh(); // 光线 //强度，光线消失位置距离，光衰退值

    var light = new THREE.PointLight(0xffffff, 1.5, 0, 1);
    light.position.set(50, 100, 50);
    scene.add(light); // const light = new THREE.AmbientLight( 0x404040 ); // soft white light
    // scene.add( light );

    onMounted(function () {
      sceneDiv.value.appendChild(renderer.domElement);
      animate();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "scene",
        ref_key: "sceneDiv",
        ref: sceneDiv
      }, null, 512);
    };
  }
};