
import * as THREE from "three";
import scene from "../scene";

import { Sky } from 'three/examples/jsm/objects/Sky.js';


export default function initSky() {
 let sky = new Sky();
  sky.scale.setScalar( 450000 );
  scene.add( sky );
 let sun = new THREE.Vector3();
  const effectController = {
    turbidity: 10,
    rayleigh: 3,
    mieCoefficient: 0.005,
    mieDirectionalG: 0.7,
    elevation: 2,
    azimuth: 180,
    // exposure: renderer.toneMappingExposure
  }; 
}

  // plane.position.set(0, 0, -6);
