import * as THREE from "three";
import scene from "../scene";
import fontJSON from "three/examples/fonts/helvetiker_bold.typeface.json";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";



export default () => {
  const fontLoad = new FontLoader();
  const fontPath = require("three/examples/fonts/gentilis_bold.typeface.json");
  console.log(fontPath);

  const font = fontLoad.parse(fontPath);
  console.log("font:", font);
  if (font) {
    const geometry = new TextGeometry("zhang xun", {
      font,
      size: 4,
      height: 1,
      curveSegments: 12,
      // bevelEnabled: true,
      // bevelThickness: 10,
      // bevelSize: 8,
      // bevelSegments: 5
    });

    const material = new THREE.MeshPhongMaterial({
      // flatShading: THREE.FlatShading,
      // transparent: true,//透明的
      // opacity: 0.6//透明度
      color: '#d14d28',
    });
    const text = new THREE.Mesh(geometry, material);
    // text.position.x = 1;
    text.position.set(-12, 0, -6);
    scene.add(text);
  }
}