
import * as THREE from "three";
import scene from "../scene";


export default (url,position)=> {
  new THREE.TextureLoader().load(url,
    (texture) => {
      const SIZE = 20;
      const img = texture.image;
      let height = (img && img.height) || SIZE;
      let width = (img && img.width) || SIZE;
      height = (SIZE / width) * height;
      width = SIZE;
      const mat = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide, transparent: true });
      const geom = new THREE.PlaneGeometry(width, height);
      const mesh = new THREE.Mesh(geom, mat);
      mesh.position.set(position.x, position.y, position.z);
      scene.add(mesh);
    }
  );
}
  // plane.position.set(0, 0, -6);
